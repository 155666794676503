angular.module('enervexSalesappApp').controller('DocumentsCtrl', function($scope, $stateParams, $q, User, Account, Job, DocumentsService, Auth, Property, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	$scope.configs = Property.configs()
	Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	}).$promise.then(function(job){
		$scope.job = job;
		$scope.permissions = Auth.jobPermissions($scope.job, $scope.configs);
	});
	DocumentsService.getDocuments().$promise.then(function(documents){
		$scope.documents = _.sortBy(documents, function(document){
			return document.name && document.name.toLowerCase();
		})
	});
});
